<template>
  <div class="misc-wrapper">

  </div>
</template>

<script>

export default {
  name:"BlankPage"
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
